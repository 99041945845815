<template>
  <div>
    <top-decorations></top-decorations>
    <div class="container">
      <h1 class="page-title">Project</h1>
      <p class="page-description">
        Student’s final projects represent the ultimate delivery of the master
        program. They are also the brightest representation of the program
        itself. They combine advanced computing technologies with scientific and
        industrial frontier applications. The applications range from: partial
        differential equations, mechanical and fluidynamical modelling,
        satellite data processing, neuroscience modelling, molecular dynamics.
      </p>
    </div>
    <projects-list></projects-list>
  </div>
</template>
<script>
import TopDecorations from "@/components/TopDecorations.vue";
import ProjectsList from "@/components/ProjectsList.vue";
export default {
  components: { TopDecorations, ProjectsList },
  data() {
    return {};
  },
  methods: {},
};
</script>